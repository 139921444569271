import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  UsergroupAddOutlined,
  CreditCardOutlined,
  CopyOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  TeamOutlined,
  SolutionOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user/index/maker" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
  {
    exact: true,
    path: '/order/user/:id',
    component: loadable(() => import("../pages/request/User")),
  },
  {
    exact: true,
    path: '/contract/user/:id',
    component: loadable(() => import("../pages/contract/User")),
  },
  {
    exact: true,
    path: '/contract/contract/:id',
    component: loadable(() => import("../pages/contract/Contract")),
  },
  {
    exact: true,
    path: '/request/user/:id',
    component: loadable(() => import("../pages/contract/request/User")),
  },
  {
    exact: true,
    path: '/request/temp/:id',
    component: loadable(() => import("../pages/contract/request/Temp")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "코니 관리",
    },
    children: [
      {
        exact: true,
        path: "/index/maker",
        sidebar: {
          label: "제작자",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        path: "/index/planer",
        sidebar: {
          label: "기획자",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        path: "/index/digital_artist",
        sidebar: {
          label: "디지털 아티스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/profile/detail/:id",
        component: loadable(() => import("../pages/user/ProfileDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register/maker",
        component: loadable(() => import("../pages/user/register/MakerRegister")),
      },
      {
        exact: true,
        visible: false,
        path: "/register/planer",
        component: loadable(() => import("../pages/user/register/PlanerRegister")),
      },
      {
        exact: true,
        visible: false,
        path: "/register/artist",
        component: loadable(() => import("../pages/user/register/ArtistRegister")),
      },
    ],
  },
  {
    exact: true,
    path: "/company",
    sidebar: {
      icon: <UsergroupAddOutlined />,
      label: "고객 정보",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "고객 정보 관리",
        },
        component: loadable(() => import("../pages/company/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/company/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/company/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/request",
    sidebar: {
      icon: <CreditCardOutlined />,
      label: "주문요청 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "주문요청 목록",
        },
        component: loadable(() => import("../pages/request/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/request/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/request/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/contract",
    sidebar: {
      icon: <CopyOutlined />,
      label: "계약 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "계약 목록",
        },
        component: loadable(() => import("../pages/contract/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/contract/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/contract/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/contract/request",
    sidebar: {
      icon: <LogoutOutlined />,
      label: "의뢰 요청서 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "의뢰 요청서 목록",
        },
        component: loadable(() => import("../pages/contract/request/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/contract/request/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/contract/request/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/point",
    sidebar: {
      icon: <MoneyCollectOutlined />,
      label: "C-Point",
    },
    component: loadable(() => import("../pages/user/PointList")),
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <TeamOutlined />,
      label: "어드민 유저관리",
    },
    component: loadable(() => import("../pages/admin/List")),
  },
  {
    exact: true,
    path: "/cost",
    sidebar: {
      icon: <SolutionOutlined />,
      label: "견적계산 기초정보 관리",
    },
    component: loadable(() => import("../pages/cost/List")),
  },
];
